<template>
  <ion-card class="tablet-centered">
    <ion-card-header class="ion-text-center">
      <ion-card-title>{{ $t('login.alreadyLogged') }}</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      <ion-button
        expand="block"
        fill="outline"
        mode="ios"
        @click="$router.push('/')"
      > {{ $t('mainNav.home') }} </ion-button>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonButton,
} from '@ionic/vue';

export default {
  name: "UserLoggedCard",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonButton,
  },
};
</script>
