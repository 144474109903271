<template>
  <div>
    <div class="pt-5 is-flex">
      <app-input
        class="w-100 mobile-input"
        note="+316"
        type="text"
        inputmode="decimal"
        :value="getProfileDetails.mobile"
        :maxlength="8"
        :errors="[fieldErrors.mobile]"
        :disabled="otpSuccessull || otpIsSending || getProfileDetails.mobile_verified_at"
        :icon="phonePortraitOutline"
        @input:update="handleMobileInput"
      >
        <template #inner-right-addon>
          <IonIcon
            v-if="getProfileDetails.mobile_verified_at || otpSuccessull"
            :icon="checkmarkDone"
            color="success"
            class="mr-4"
          />
        </template>
      </app-input>

      <transition name="fade">
        <span
          v-if="showVerifyButton"
          class="verify-container ml-2"
        >
          <ion-button
            :disabled="
              !!otpSuccessull ||
                !!otpIsSending ||
                !!otp.timer ||
                String(getProfileDetails.mobile).length < 8 ||
                !!getProfileDetails.mobile_verified_at
            "
            class="verify-btn uppercase"
            fill="outline"
            mode="ios"
            @click="sendOpt"
          >
            {{ $t('verifyProfile.mobile.verify') }}
          </ion-button>
        </span>
      </transition>
    </div>

    <div class="is-flex ion-justify-content-end">
      <ion-button
        v-if="otpSuccessull || getProfileDetails.mobile_verified_at"
        mode="ios"
        size="small"
        @click="handleMobileInput(String(getProfileDetails.mobile))"
      > {{ $t('commonKeys.edit') }} </ion-button>
    </div>

    <transition name="slide">
      <ion-row v-if="otp.isOtpSent && !otpSuccessull">
        <ion-col
          size="7"
          class="ion-no-margin ion-no-padding"
        >
          <AppInput
            :value="getProfileDetails.otp"
            :maxlength="6"
            :errors="[fieldErrors.otp]"
            inputmode="decimal"
            :icon="appsOutlineIcon"
            :disabled="otpSuccessull"
            class="otp-input"
            @input:update="verifyOtp"
          />
        </ion-col>

        <ion-col
          size="5"
          class="ml-auto ion-no-padding"
        >
          <div class="ion-no-margin">
            <ion-button
              :disabled="!!otpSuccessull || !!otpIsSending || !!otp.timer"
              fill="clear"
              expand="block"
              mode="ios"
              @click="sendOpt"
            >
              <span> {{ $t('verifyProfile.mobile.resendBtn') }} </span>

              <span> &nbsp; {{ otp.timer }} </span>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </transition>
  </div>
</template>

<script>
import AppInput from "../common/AppInput";
import { mapGetters } from 'vuex';
import { appsOutline as appsOutlineIcon, checkmarkDone, phonePortraitOutline } from 'ionicons/icons';
import { IonCol, IonRow, IonButton, IonIcon} from "@ionic/vue";

export default {
  props: {
    disableVerification: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AppInput,
    IonIcon,
    IonCol,
    IonRow,
    IonButton,
  },

  data () {
    return {
      fieldErrors: {
        mobile: [],
        otp: '',
      },

      otpSuccessull: false,
      otpIsSending: false,
      otp: {
        code: '',
        timer: '',
        isSending: false,
        isOtpSent: false,
      },

      disableMobileInputFl: false,

      appsOutlineIcon,
      checkmarkDone,
      phonePortraitOutline,
    };
  },

  computed: {
    ...mapGetters('profile', ['getProfileDetails']),

    disableMobileInput () {
      return this.disableMobileInputFl || this.getProfileDetails.mobile_verified_at;
    },

    showVerifyButton () {
      return (this.getProfileDetails.mobile && this.getProfileDetails.mobile.length >= 8 && !this.getProfileDetails.mobile_verified_at && !this.otpSuccessull);
    },
  },

  methods: {
    handleMobileInput (event) {
      this.$store.commit('profile/updateProfileDetails', { mobile: event });
      this.otpSuccessull = null;
      this.otpIsSending = null;
      clearInterval(window.timeInterval);
      this.timerValue = '';
      this.fieldErrors.otp = null;
      this.otp.timer = '';
      this.otp.isOtpSent = false;
      this.$store.commit('profile/updateProfileDetails', { mobile_verified_at: null });
    },
    verifyOtp (event) {
      this.$store.commit('profile/updateProfileDetails', { otp: event });

      if (String(this.getProfileDetails.otp).length !== 6) {
        return;
      }

      this.otpIsSending = true;

      this.$store
        .dispatch('profile/verifyOtp', {
          otp: parseInt(this.getProfileDetails.otp),
          mobile: parseInt(this.getProfileDetails.mobile),
        })
        .then((response) => {
          this.otpIsSending = false;
          this.$store.commit('profile/updateProfileDetails', { otp: parseInt(this.getProfileDetails.otp) });
          if (response.data.isVerified) {
            this.otpSuccessull = true;
            this.fieldErrors.otp = this.$t('verifyProfile.mobile.successOtpMessage');
            clearInterval(window.timeInterval);
            this.timerValue = '';
          } else {
            this.otpSuccessull = false;

            this.fieldErrors.otp = this.$t('verifyProfile.mobile.otpFailed');
          }
        })
        .catch(() => {
          this.fieldErrors.otp = 'invalidOtpMessage';

          this.otpIsSending = false;
          this.otpSuccessull = false;
        });
    },
    sendOpt () {
      this.otp.isSending = true;

      const path = 'profile/sendOtp';
      const payload = { mobile: parseInt(this.getProfileDetails.mobile) };

      this.$store
        .dispatch(path, payload)
        .then(this.handleOtpSendSuccess)
        .catch(this.handleOtpSendError);
    },
    handleOtpSendSuccess () {
      this.otp.isOtpSent = true;
      this.otp.isSending = false;
      this.fieldErrors.mobile = [];

      this.startTimer();

    },
    handleOtpSendError (errorResponse) {
      this.otp.isSending = false;

      const [errorMessage] =
        errorResponse.response &&
        errorResponse.response.data &&
        errorResponse.response.data.errors &&
        errorResponse.response.data.errors.mobile;

      this.fieldErrors.mobile = errorMessage;

    },
    startTimer (timer = 60) {
      let minutes;
      let seconds;
      clearInterval(window.timeInterval);

      this.otp.timer = '01:00';

      window.timeInterval = setInterval(() => {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;
        this.otp.timer = minutes + ':' + seconds;

        if (--timer < 0) {
          this.otp.timer = '';
          clearInterval(window.timeInterval);
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
  .checkmark {
    font-size: 24px;
    align-self: center;
  }
</style>
<style>
  .mobile-input .input {
    font-size: 17px !important;
    display: flex;
    min-height: 100%;
    align-items: center;
    margin: 0;
  }
</style>
