<template>
  <!-- <IonSpinner v-if="isLoading"/> -->
  <ion-content
    v-if="!verifyPageCompleted"
    class="top-safe-space"
  >
    <section class="ion-padding tablet-centered">
      <div class="main-content pt-5">
        <div class="is-flex ion-justify-content-center">
          <AppImg
            is-transparent
            img-src="./assets/medicast-logo.png"
            style="width: 100px; height: 100px"
          />
        </div>
        <div class="is-flex ion-align-items-center ion-justify-content-center is-flex-direction-column ion-margin-bottom">
          <p class="mb-1 fz-24 fw-300">{{ $t('profile.welcomeText') }}</p>
          <h2 class="title fw-400">Medicast</h2>
          <h2 class="fw-700">{{ $t('commonKeys.verification') }} 1/2</h2>
        </div>

        <div class="is-flex is-flex-direction-column w-100">
          <AppInput
            :value="getUser.email"
            class="w-100 ion-margin-bottom"
            disabled
            :icon="mailOutline"
            :placeholder="$t('profile.emailLabel')"
          />

          <AppInput
            :value="postalCodeValue"
            class="w-100"
            :icon="homeOutline"
            maxlength="7"
            :placeholder="$t('profile.postalCodeLabel')"
            @input:update="postalCodeValue = $event"
          />

          <MobileNumberInput />

          <div
            v-if="!getUserProfessions.length"
            class="ion-padding-top ion-padding-bottom"
          >

            <template v-if="professionsState.quizView">
              <UserProfessionsSelect
                :professions-list="professionsList"
                :submit-on-profession-update="false"
                :add-user-profession-fn="professionAdded"
                :selected-profession-ids="[]"
                :has-cancel="false"
                :has-save="false"
                :show-title="false"
                :prefill-bignumber-value="professionsState.prefillBignumberValue"
                @update:profession-details="firstProfessionUpdated"
                @update:bignumber-details="bignumberDetails = $event"
              />
              <IonButton
                v-if="professionsState.selectedProfessions.filter(p => p.profession_id).length"
                class="px-6"
                color="primary"
                fill="outline"
                mode="ios"
                outlined
                expand="block"
                @click="professionsState.splitSelectionOpen = true"
              > {{ $t('verifyProfile.addAnotherProfession') }} </IonButton>

              <ion-modal
                :is-open="professionsState.splitSelectionOpen"
                @didDismiss="professionsState.splitSelectionOpen = false"
              >
                <div class="card p-4">
                  <EditSplitProfessionModal
                    :professions-list="professionsList"
                    :professions-additions-list="professionsAdditionList"
                    :selected-profession-ids="professionsState.selectedProfessions.map(p => p.profession_id)"
                    :add-user-profession-fn="splitSelectionProfessionAdded"
                    @update:bignumber-details="bignumberDetails = $event"
                    @close="closeSplitModal"
                  />
                </div>
              </ion-modal>
            </template>

            <UserProfessions
              v-else
              class="column"
              :professions="professionsState.selectedProfessions.filter(p => p.profession_id)"
              :add-user-profession-fn="professionAdded"
              :fetch-user-professions-fn="getProfessionList"
              :delete-user-profession-fn="removeProfession"
              can-add-profession
              can-delete-profession
              can-edit-profession
              :show-title="false"
              @update:bignumber-details="bignumberDetails = $event"
            />
          </div>
        </div>

        <div
          class="pt-5"
          style="margin-top: auto"
        >
          <ion-row>
            <ion-col>
              <ion-button
                fill="outline"
                expand="block"
                mode="ios"
                shape="round"
                class="fw-500 fz-18 uppercase"
                @click="confirmSkip"
              >
                {{ $t('commonKeys.skip') }}
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button
                expand="block"
                mode="ios"
                shape="round"
                class="fw-500 fz-18 uppercase"
                @click="verifyProfile"
              >
                {{ $t('commonKeys.verifyBtn') }}
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
        <div class="is-flex ion-justify-content-center">
          <ion-button
            mode="ios"
            fill="clear"
            class="uppercase fw-500 fz-12 contact-us"
            @click.prevent="openUrl('https://app.medicast.nl/contact')"
          >
            {{ $t('contact.us') }}
          </ion-button>
        </div>
      </div>
    </section>

    <IonLoading :is-open="loading"/>
  </ion-content>
  <CompleteProfile
    v-else
    @close-modal="closeModal"
    @completed="completeProfilePageCompleted = true"
  />

</template>

<script>
import AppInput from '@/components/common/AppInput.vue';
import useInAppBrowser from '@/composables/useInAppBrowser';

import {
  IonContent,
  IonPage,
  IonCol,
  IonRow,
  IonButton,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonLoading,
  alertController,
  modalController,
  IonSpinner,
  IonCard,
  IonImg,
  IonModal,
} from '@ionic/vue';


import { mailOutline, checkmarkDone, closeOutline, schoolOutline, homeOutline } from 'ionicons/icons';

import MobileNumberInput from "@/components/profile/MobileNumberInput";
import AppErrorField from "@/components/common/AppErrorField";
import AppSelect from "@/components/common/AppSelect";
import CompleteProfile from "@/components/login/CompleteProfile";
import useRequestToast from "@/composables/useRequestToast";
import { mapGetters } from 'vuex';
import AppImg from '@/components/common/AppImg.vue';
import UserProfessionsSelect from "@/components/profile/professions/UserProfessionSelect";
import UserProfessions from "@/components/profile/professions/UserProfessions";
import UserProfessionsSplitSelection from "@/components/profile/professions/UserProfessionsSplitSelection";
import EditSplitProfessionModal from "@/components/profile/professions/EditSplitProfessionModal";

export default {
  name: "VerifyModal",
  components: {
    EditSplitProfessionModal,
    AppErrorField,
    MobileNumberInput,
    IonContent,
    IonPage,
    IonCol,
    IonRow,
    IonButton,
    IonSelect,
    IonSelectOption,
    IonIcon,
    IonLoading,
    AppInput,
    AppSelect,
    CompleteProfile,
    IonSpinner,
    IonCard,
    IonImg,
    IonModal,
    AppImg,
    UserProfessionsSelect,
    UserProfessions,
    UserProfessionsSplitSelection,
  },

  props: {
    close: {
      type: Function,
      default: modalController.dismiss,
    },
    verifyCompleted: {
      type: Boolean,
      default: false,
    },
    completeCompleted: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    const { openErrorToast } = useRequestToast();
    const { openUrl } = useInAppBrowser();

    return {
      fieldErrors: {
        bignumber: [],
        profession_id: [],
        profession_ids: [],
      },

      bignumberDetails: {},
      loading: false,

      openErrorToast,

      mailOutline,
      checkmarkDone,
      closeOutline,
      schoolOutline,
      homeOutline,
      verifyPageCompleted: false,
      completeProfilePageCompleted: false,
      isLoading: true,
      openUrl,


      professionsState: {
        quizView: true,
        splitSelectionOpen: false,
        prefillBignumberValue: null,
        selectedProfessions: [],
      },
    };
  },

  computed: {
    ...mapGetters('profile', ['getProfessionFromOccupation', 'getProfileDetails', 'getUserProfessions']),
    ...mapGetters('authentication', ['getUser']),

    professionsList () {
      return this.getProfessionFromOccupation.filter(p => !p.is_big_addition);
    },

    professionsAdditionList () {
      return this.getProfessionFromOccupation.filter(p => p.is_big_addition);
    },

    postalCodeValue: {
      get() { return this.getProfileDetails.postal_code; },

      set (value) { this.$store.commit('profile/updateProfileDetails', { postal_code: value });},
    },
  },

  methods: {
    async confirmSkip() {
      const alert = await alertController
        .create({
          header: this.$t('verifyProfile.skipConfirm.message'),
          message: this.$t('verifyProfile.skipConfirm.messageBody'),
          buttons: [
            { text: this.$t('commonKeys.cancel') },
            {
              text: this.$t('commonKeys.skip'),
              handler: this.closeModal,
            },
          ],
        });
      return alert.present();
    },

    closeModal () {
      this.completeProfilePageCompleted ? this.close() : this.verifyPageCompleted = true;
    },

    verifyProfile() {
      this.loading = true;

      this.$store.dispatch('profile/verifyAccount', this.getPostObject())
        .then(async () => {
          this.closeModal();
        })
        .catch((err) => {
          this.openErrorToast(err);
          this.fieldErrors = err?.response?.data?.errors ?? {};
        })
        .finally(() => {
          this.loading = false;
        });
    },

    getPostObject () {
      const mobileValue = this.getProfileDetails.mobile;

      const payloadWithoutProfessions = {
        postal_code: this.postalCodeValue,
        mobile: mobileValue ? parseInt(mobileValue) : null,
        otp: mobileValue ? parseInt(this.getProfileDetails.otp) : null,
      };

      const payloadWithProfessions = {
        professions: this.getFormattedProfessionsPostArray(),
      };


      if (this.getUserProfessions.length) {
        return payloadWithoutProfessions;
      }

      return Object.assign({}, payloadWithoutProfessions, payloadWithProfessions);
    },


    async fetchProfileData () {
      this.$store.dispatch('profile/setProfessionsFromOccupations');
    },

    redirectToHome () {
      return this.$router.push('/');
    },

    getProfessionNameById(professionId) {
      return this.getProfessionFromOccupation.find(p => p.id === professionId)?.name ?? null;
    },
    getFormattedProfessionsPostArray () {
      const professions = [];

      const formatProfessionObject = (profession) => {
        return {
          profession_ids: [profession.profession_id],
          registration_number: profession.registration_number,
          bignumber: profession.bignumber_id,
          bignumber_invalid_response: profession?.binumber?.bignumber_check_required ?? 0,
          isBigNumberAvailable: profession.bignumber_id ? 1 : 0,
        };
      };

      const bigNumberMap = {};

      this.professionsState.selectedProfessions.forEach((profession, index) => {
        const existingBignumberIndex = bigNumberMap[profession.bignumber_id];
        if (existingBignumberIndex) {
          professions[existingBignumberIndex].profession_ids.push(profession.profession_id);
        } else {
          professions.push(formatProfessionObject(profession));
          if (profession.bignumber_id) {
            bigNumberMap[profession.bignumber_id] = index;
          }
        }
      });

      return professions;
    },
    professionAdded (object, isMain = false) {
      object.profession_ids.forEach((professionId) => {
        const professionObj = {
          main: isMain,
          bignumber: object.bignumber ? {
            id: Math.random(),
            big_number: object.bignumber,
            bignumber_check_required: object.bignumber_invalid_response ? 1 : 0,
          } : null,
          bignumber_id: object.bignumber,
          id: this.professionsState.selectedProfessions.length,
          is_big_addition: object.is_big_addition,
          profession_details: {
            id: professionId,
            name: this.getProfessionNameById(professionId),
          },
          profession_id: professionId,
          registration_number: object.registration_number,
        };
        this.professionsState.selectedProfessions.push(professionObj);
      });

      if (this.professionsState.selectedProfessions.filter(p => p.profession_id).length > 1) {
        this.professionsState.quizView = false;
      }

      return Promise.resolve();
    },
    removeProfession (payload) {
      const index = this.professionsState.selectedProfessions.findIndex(stateProfession => stateProfession.id === payload.id);

      if (index > -1) {
        this.professionsState.selectedProfessions.splice(index, 1);
      }

      if (this.professionsState.selectedProfessions.filter(p => p.profession_id).length === 0) {
        this.professionsState.quizView = true;
      }

      return Promise.resolve();
    },
    getProfessionList () {
      return Promise.resolve({ data: this.professionsState.selectedProfessions });
    },

    firstProfessionUpdated (details) {
      const firstProfessions = this.professionsState.selectedProfessions.filter(stateProfession => stateProfession.main);
      if (firstProfessions.length) {
        firstProfessions.forEach(p => this.removeProfession(p));
      }
      if (details) {
        this.professionAdded(details, true);
      }
    },

    async splitSelectionProfessionAdded (professionObj) {
      await this.professionAdded(professionObj);
      this.closeSplitModal();
    },

    closeSplitModal() {
      setTimeout(() => {
        this.professionsState.splitSelectionOpen = false;
      }, 250);
    },
  },

  mounted() {
    this.verifyPageCompleted = this.verifyCompleted;
    this.completeProfilePageCompleted = this.completeCompleted;
    this.fetchProfileData();
    this.redirectToHome();
  },
};
</script>

<style scoped lang="scss">
    .main-content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .title {
        font-size: 50px;
    }

    .subtitle {
        padding-left: 40px;
    }

    .proffesion-card {
      margin-left: 0;
      margin-right: 0;
      padding: 6px 0;
      border-radius: 10px;
      background: var(--input-background);
    }
    ion-button.uppercase {
      --padding-start: 5px;
      --padding-end: 5px;
    }

    @media screen and (max-width: 320px) {
      .title {
        font-size: 60px;
        }
    }


    .status-icon {
      font-size: 24px;
      display: flex;
      align-self: center;
    }
    .top-logo {
      width: 100px;
      height: 100px;
      text-align: center;
    }
</style>

<style lang="scss">
  .proffesion-select {
    ion-select {
       --padding-start: 0;
       --placeholder-opacity: 0.5;
      }
  }
  .verify-modal .modal-wrapper {
    width: 100%;
    height: 100%;
  }

  .verify-modal::part(content) {
    width: 100%;
    height: 100%;
  }

</style>
