<template>
  <ion-content class="top-safe-space">
    <section class="ion-padding tablet-centered">
      <AppImg
        is-transparent
        img-src="./assets/medicast-logo.png"
        style="width: 100px; height: 100px; margin: 0 auto"
      />
      <div class="main-content pt-5">
        <div class="ion-margin-bottom">
          <p class="mb-1 fz-24 fw-300 ion-text-center">{{ $t('profile.welcomeText') }}</p>
          <h2 class="title fw-400 ion-text-center">Medicast</h2>
          <h2 class="fw-700 ion-text-center">{{ $t('commonKeys.verification') }} 2/2</h2>
        </div>

        <ion-card
          color="medium"
          class="profile-card p-inline-0 ion-margin-vertical"
        >
          <AppSelect
            v-model="salutation"
            :placeholder="$t('profile.salutationLabel')"
            :items="[{id: 'Dhr.', name: 'Dhr.'}, {id: 'Mevr.', name: 'Mevr.'},]"
          />
        </ion-card>

        <AppInput
          class="w-100 ion-margin-bottom"
          :placeholder="$t('profile.titleLabel')"
          :value="title"
          @input:update="title = $event"
        />

        <AppInput
          class="w-100 ion-margin-bottom"
          :placeholder="$t('profile.firstNameLabel')"
          :value="firstName"
          @input:update="firstName = $event"
        />

        <AppInput
          class="w-100 ion-margin-bottom"
          :placeholder="$t('profile.insertionLabel')"
          :value="insertion"
          @input:update="insertion = $event"
        />

        <AppInput
          class="w-100 ion-margin-bottom"
          :placeholder="$t('profile.lastNameLabel')"
          :value="lastName"
          @input:update="lastName = $event"
        />

        <AppInput
          class="w-100 ion-margin-bottom"
          :placeholder="$t('profile.streetLabel')"
          :value="street"
          @input:update="street = $event"
        />

        <AppInput
          class="w-100 ion-margin-bottom"
          :placeholder="$t('profile.additionLabel')"
          :value="addition"
          @input:update="addition = $event"
        />

        <p class="offer-label">{{ $t('profile.sendOffersLabel') }}</p>
        <ion-card
          color="medium"
          class="profile-card p-inline-0 ion-margin-vertical"
        >
          <AppSelect
            v-model="specialOffers"
            :placeholder="$t('profile.specialOffersLabel')"
            :items="[{id: 'Never', name: $t('profile.specialOffers.never')}, {id: 'Monthly', name: $t('profile.specialOffers.monthly')}, {id: 'Weekly', name: $t('profile.specialOffers.weekly')}, {id: 'Daily', name: $t('profile.specialOffers.daily')},]"
          />
        </ion-card>

        <ion-item>
          <ion-checkbox
            :checked="newsletterAbroad"
            :model-value="newsletterAbroad"
            color="primary"
            slot="start"
            @update:modelValue="newsletterAbroad = $event"
          />
          <ion-label class="offer-check">{{ $t('profile.sendMeOffersCheckBox') }}</ion-label>
        </ion-item>
        <ion-row>
          <ion-col>
            <ion-button
              fill="outline"
              expand="block"
              mode="ios"
              shape="round"
              class="fw-500 fz-18 uppercase"
              @click="redirectNext"
            >
              {{$t('commonKeys.skip')}}
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              expand="block"
              mode="ios"
              shape="round"
              class="fw-500 fz-18 uppercase"
              @click="verifyProfile"
            >
              {{$t('verifyProfile.title')}}
            </ion-button>
          </ion-col>
        </ion-row>

        <div class="is-flex ion-justify-content-center">
          <ion-button
            mode="ios"
            fill="clear"
            class="uppercase fw-500 fz-12 contact-us"
            @click.prevent="openUrl('https://app.medicast.nl/contact')"
          >
            {{ $t('contact.us') }}
          </ion-button>
        </div>
      </div>
      <IonLoading :is-open="loading" />
    </section>
  </ion-content>
</template>

<script>
import AppInput from '@/components/common/AppInput.vue';
import AppSelect from '@/components/common/AppSelect.vue';
import useRequestToast from "@/composables/useRequestToast";
import useInAppBrowser from '@/composables/useInAppBrowser';

import {
  IonContent,
  IonPage,
  IonCol,
  IonRow,
  IonButton,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonLoading,
  IonCard,
  IonImg,
} from '@ionic/vue';
import { mapGetters } from "vuex";
import AppImg from '@/components/common/AppImg.vue';

export default {
  name: "CompleteProfile",

  components: {
    AppInput,
    AppSelect,
    IonContent,
    IonPage,
    IonCol,
    IonRow,
    IonButton,
    IonItem,
    IonCheckbox,
    IonLabel,
    IonLoading,
    IonCard,
    IonImg,
    AppImg,
  },

  data () {
    const { openErrorToast } = useRequestToast();
    const { openUrl } = useInAppBrowser();

    return {
      loading: false,
      openErrorToast,
      openUrl,
    };
  },

  computed: {
    ...mapGetters('profile', ['getProfileDetails', 'profileDetailsFetched']),

    salutation: {
      get() { return this.getProfileDetails.salutation; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { salutation: value });},
    },
    firstName: {
      get() { return this.getProfileDetails.first_name; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { first_name: value });},
    },
    title: {
      get() { return this.getProfileDetails.title; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { title: value });},
    },
    insertion: {
      get() { return this.getProfileDetails.insertion; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { insertion: value });},
    },
    street: {
      get() { return this.getProfileDetails.street; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { street: value });},
    },
    lastName: {
      get() { return this.getProfileDetails.last_name; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { last_name: value });},
    },
    number: {
      get() { return this.getProfileDetails.number; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { number: value });},
    },
    addition: {
      get() { return this.getProfileDetails.street_addition; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { street_addition: value });},
    },
    specialOffers: {
      get() { return this.getProfileDetails.newsletter_frequency || 'Monthly'; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { newsletter_frequency: value });},
    },
    newsletterAbroad: {
      get() { return this.getProfileDetails.newsletter_abroad !== 0; },
      set (value) { this.$store.commit('profile/updateProfileDetails', { newsletter_frequency: value });},
    },

  },


  methods: {
    verifyProfile () {
      this.loading = true;
      this.$store
        .dispatch('profile/completeProfile', {
          salutation: this.salutation,
          title: this.title,
          first_name: this.firstName,
          insertion: this.insertion,
          last_name: this.lastName,
          street: this.street,
          street_number: this.number,
          street_addition: this.addition,
          newsletter_frequency: this.specialOffers,
          newsletter_abroad: this.newsletterAbroad,
        })
        .then(() => this.redirectNext())
        .catch((err) => {
          this.openErrorToast(err);
        })
        .finally(() => this.loading = false);
    },

    redirectNext() {
      this.$emit('completed');
      this.$emit('close-modal');
    },
  },
};
</script>

<style scoped lang="scss">
  .main-content {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 50px;
  }

  .subtitle {
    padding-left: 40px;
  }
  .offer-check {
    font-size: 14px;
    white-space: normal;
  }
  .offer-label {
    padding-left: 20px;
  }
  .profile-card {
    margin-left: 0;
    margin-right: 0;
    padding: 6px 0;
    border-radius: 10px;
    background: var(--input-background);
  }
  ion-button.uppercase {
      --padding-start: 5px;
      --padding-end: 5px;
    }

  @media screen and (max-width: 320px) {
    .title {
      font-size: 60px;
    }
  }
</style>

<style lang="scss">
  .profile-card ion-select {
    --placeholder-opacity: 0.5;
  }
</style>
